import React from "react";

const NotFoundPage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100%",
        gap: "4rem",
        flexWrap: "wrap",
      }}
    >
      {/* Text Section */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          flex: "1",
        }}
      >
        <p
          style={{
            fontSize: "6rem",
            fontWeight: "bold",
            color: "#D1D5DB",
            letterSpacing: "0.05em",
          }}
        >
          404
        </p>
        <p
          style={{
            fontSize: "3rem",
            fontWeight: "bold",
            color: "#D1D5DB",
            marginTop: "0.5rem",
            letterSpacing: "0.05em",
          }}
        >
          Page Not Found
        </p>
        <p
          style={{
            fontSize: "1.5rem",
            color: "#6B7280",
            margin: "3rem 0",
          }}
        >
          Sorry, the page you are looking for could not be found.
        </p>
        <a
          href="/"
          title="Return Home"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            backgroundColor: "#2563EB",
            color: "#F9FAFB",
            padding: "0.5rem 1rem",
            borderRadius: "0.375rem",
            textDecoration: "none",
            transition: "background-color 0.15s ease-in-out",
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "#1D4ED8";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "#2563EB";
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ height: "1.25rem", width: "1.25rem" }}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
          <span>Return Home</span>
        </a>
      </div>

      {/* Illustration Section */}
      <div
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          padding: "1rem",
        }}
      >
        <svg
          style={{ width: "100%", color: "#2563EB" }}
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 1120.59226 777.91584"
        >
          <title>not found</title>
          {/* SVG content */}
          <circle cx="212.59226" cy="103" r="64" fill="#ff6584" />
          <path
            d="M563.68016,404.16381c0,151.01141-89.77389,203.73895-200.51559,203.73895S162.649,555.17522,162.649,404.16381,363.16457,61.04208,363.16457,61.04208,563.68016,253.1524,563.68016,404.16381Z"
            transform="translate(-39.70387 -61.04208)"
            fill="#cbd5e1"
          />
          {/* Truncated for brevity */}
        </svg>
      </div>
    </div>
  );
};

export default NotFoundPage;
