import React from "react";
import classNames from "classnames";
import "./style.css";
import Tabs, { TabList, Tab, TabPanel } from "../../elements/Tabs";

import { SectionProps } from "../../../utils/SectionProps";
import Image from "../../elements/Image";
import SectionHeader from "../../sections/partials/SectionHeader";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class Services extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tabs section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tabs-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Our Services",
      paragraph: "Empowering businesses through strategic solutions.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Tabs active="tab-a">
              <TabList>
                <Tab tabId="tab-a">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("../../../assets/images/features-tabs-icon-01.svg")}
                      alt="Tab icon 01"
                      width={80}
                      height={80}
                    />
                  </div>
                  <div
                    className="text-color-high fw-600 text-sm"
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    Software Development
                  </div>
                </Tab>
                <Tab tabId="tab-b">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("../../../assets/images/features-tabs-icon-02.svg")}
                      alt="Tab icon 02"
                      width={80}
                      height={80}
                    />
                  </div>
                  <div
                    className="text-color-high fw-600 text-sm"
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    Emerging Technology
                  </div>
                </Tab>
                <Tab tabId="tab-d">
                  <div className="features-tabs-tab-image mb-12">
                    <Image
                      src={require("../../../assets/images/features-tabs-icon-04.svg")}
                      alt="Tab icon 04"
                      width={80}
                      height={80}
                    />
                  </div>
                  <div
                    className="text-color-high fw-600 text-sm"
                    style={{
                      fontSize: "22px",
                    }}
                  >
                    Cloud & Infra
                  </div>
                </Tab>
              </TabList>
              <TabPanel id="tab-a">
                {/* <Image
                  className="has-shadow"
                  src={require("../../../assets/images/features-tabs-image.png")}
                  alt="Features tabs image 01"
                  width={896}
                  height={504}
                /> */}
                <div className="service_image_container">
                  <div>
                    <img src="/icons/E-Commerse.png" alt="" />
                    <p>Ecommerce Solutions</p>
                  </div>
                  <div>
                    <img src="/icons/Epr.png" alt="" />
                    <p>Custom EPR</p>
                  </div>
                  <div>
                    <img src="/icons/AppWebsite.png" alt="" />
                    <p>Fintech Solutions</p>
                  </div>
                  <div>
                    <img src="/icons/GameDevelopment.png" alt="" />
                    <p>Edutech Solutions</p>
                  </div>
                  <div>
                    <img src="/icons/applications.png" alt="" />
                    <p>On Demand Applications</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel id="tab-b">
                {/* <Image
                  className="has-shadow"
                  src={require("../../../assets/images/features-tabs-image.png")}
                  alt="Features tabs image 02"
                  width={896}
                  height={504}
                /> */}
                <div className="service_image_container">
                  <div>
                    <img src="/icons/AI_ML.png" alt="" />
                    <p>AI/ML</p>
                  </div>
                  <div>
                    <img src="/icons/Seo.png" alt="" />
                    <p>Web 3.0</p>
                  </div>
                  <div>
                    <img src="/icons/PerformanceMarketing.png" alt="" />
                    <p>Industry 4.0 Solutions & IoT</p>
                  </div>
                  <div>
                    <img src="/icons/Branding.png" alt="" />
                    <p>AR/VR & Metaverse</p>
                  </div>
                </div>
              </TabPanel>
              <TabPanel id="tab-d">
                {/* <Image
                  className="has-shadow"
                  src={require("../../../assets/images/features-tabs-image.png")}
                  alt="Features tabs image 04"
                  width={896}
                  height={504}
                /> */}
                <div className="service_image_container">
                  <div>
                    <img src="/icons/ScrumMaster.png" alt="" />
                    <p>Cloud Bill Optimsation</p>
                  </div>
                  <div>
                    <img src="/icons/ProjectManager.png" alt="" />
                    <p>Cloud Managed Services</p>
                  </div>
                  <div>
                    <img src="/icons/cybersecurity.png" alt="" />
                    <p>Cybersecurity</p>
                  </div>
                  <div>
                    <img src="/icons/ProductManager.png" alt="" />
                    <p>Cloud Consulting</p>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    );
  }
}

Services.propTypes = propTypes;
Services.defaultProps = defaultProps;

export default Services;
