import React from "react";
import SectionHeader from "../../sections/partials/SectionHeader";
import Accordion from "../../elements/Accordion";
import AccordionItem from "../../elements/AccordionItem";
import GenericSection from "../../sections/GenericSection";
import { faqs_data } from "../../../utils/data/faqs.data";
// import section header
// import SectionHeader from "../components/sections/partials/SectionHeader";
// import GenericSection from "../components/sections/GenericSection";
// import Accordion from "../components/elements/Accordion";
// import AccordionItem from "../components/elements/AccordionItem";

class Faqs extends React.Component {
  state = {
    demoModalActive: false,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  };

  render() {
    const genericSection04Header = {
      title: "Frequently asked questions",
    };

    return (
      <React.Fragment>
        <GenericSection topDivider>
          <div className="container-xs">
            <SectionHeader
              data={genericSection04Header}
              className="center-content"
            />
            <Accordion>
              {/* <AccordionItem title="Nisi porta lorem mollis aliquam ut." active>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
              <AccordionItem title="Nisi porta lorem mollis aliquam ut.">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem> */}
              {faqs_data?.map((item, ind) => (
                <AccordionItem title={item.question} key={ind}>
                  {item?.answer}
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </GenericSection>
      </React.Fragment>
    );
  }
}

export default Faqs;
