import React, { Component } from "react";
import "./style.css";
// Sample icons (replace with your preferred icons or SVGs)
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";

class ContactDetails extends Component {
  render() {
    return (
      <div className="contact-details" style={{ color: "white" }}>
        <h3 className="contact-title">Get in Touch</h3>
        <p>
        Contact us for tailored IT solutions. We’re ready to address your questions and help drive your business growth!
        </p>
        <hr />
        <ul className="contact-list">
          <li className="contact-item">
            <div
              style={{
                border: "1px solid gray",
                padding: "10px",
                borderRadius: "5px",
                background: "white",
              }}
            >
              <FaPhoneAlt className="contact-icon" style={{ margin: "0" }} />
            </div>
            <div>
              <strong>Phone:</strong>
              <a href="tel:+919779121930" style={{ color: "white" }}>
                +91 9779121930
              </a>
            </div>
          </li>
          <li className="contact-item">
            <div
              style={{
                border: "1px solid gray",
                padding: "10px",
                borderRadius: "5px",
                background: "white",
              }}
            >
              <FaEnvelope className="contact-icon" style={{ margin: "0" }} />
            </div>
            <div>
              <strong>Email:</strong>
              <a href="mailto:info@orbitsglobal.com" style={{ color: "white" }}>
              info@orbitsglobal.com
              </a>
            </div>
          </li>
          <li className="contact-item">
            <div
              style={{
                border: "1px solid gray",
                padding: "10px",
                borderRadius: "5px",
                background: "white",
              }}
            >
              <FaMapMarkerAlt
                className="contact-icon"
                style={{ margin: "0" }}
              />
            </div>
            <div>
              <strong>Location:</strong>
              <span>
              Plot No. 13, Sub. Major Laxmi Chand Rd, Maruti Udyog, Sector 18, Gurugram, Sarhol, Haryana 122015
              </span>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default ContactDetails;
