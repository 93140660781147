import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";
import Input from "../../elements/Input";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class Newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailError: "",
    };
  }

  handleChange = (event) => {
    this.setState({
      email: event.target.value,
      emailError: "", // Clear error on change
    });
  };

  validateEmail = () => {
    const { email } = this.state;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      this.setState({ emailError: "Please enter a valid email address." });
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault(); // Prevent form submission
    if (this.validateEmail()) {
      console.log("Email submitted:", this.state.email);
      // Additional logic for submitting email can be added here
      this.setState({ email: "" }); // Clear input after submission
    }
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const { email, emailError } = this.state;

    const outerClasses = classNames(
      "cta section center-content-mobile",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "cta-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      split && "cta-split"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className="cta-slogan">
              <h3 className="m-0">
                Stay updated with our IT services and solutions!
              </h3>
            </div>
            <div className="cta-action">
              <form onSubmit={this.handleSubmit}>
                <Input
                  id="newsletter"
                  type="email"
                  value={email}
                  onChange={this.handleChange}
                  label="Subscribe"
                  labelHidden
                  // hasIcon="right"
                  placeholder="Your email"
                >
                  {/* <svg
                    width="16"
                    height="12"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      cursor:'pointer'
                    }}
                  >
                    <path
                      d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                      fill="#376DF9"
                    />
                  </svg> */}
                  <button  className="button-primary" style={{fontSize:"16px"}}>
                  Subscribe
                </button>
                </Input>
                {emailError && <p className="error-message">{emailError}</p>}
                {/* <button  className="button-primary">
                  Subscribe
                </button> */}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Newsletter.propTypes = propTypes;
Newsletter.defaultProps = defaultProps;

export default Newsletter;
