import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../../utils/SectionProps";

import Timeline from "../../elements/Timeline";
import TimelineItem from "../../elements/TimelineItem";
import SectionHeader from "../../sections/partials/SectionHeader";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class Process extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "roadmap section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "roadmap-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const sectionHeader = {
      title: "Our Process",
      paragraph: "Take the first step in your digital journey with us.",
    };

    return (
      <section {...props} className={outerClasses} id="process">
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <Timeline>
              <TimelineItem title="Discovery & Consultation">
                We start by understanding your business goals and technology
                challenges. Through detailed consultations, we assess your needs
                to create a tailored solution that addresses your specific
                requirements.
              </TimelineItem>
              <TimelineItem title="Solution Alignment with Partners">
                After identifying the best-fit solution, we collaborate with our
                trusted partners to align the solution with your needs. This
                ensures that the most suitable technology and expertise are
                applied to your project.
              </TimelineItem>
              <TimelineItem title="Development & Implementation by Partners">
                Our expert partners take over the development process,
                leveraging their specialized skills to build and integrate the
                solution, while we oversee the execution to ensure quality and
                consistency.
              </TimelineItem>
              <TimelineItem title="Ongoing Support & Optimization">
                Once the solution is deployed, we provide ongoing support and
                continuous optimization. We work closely with our partners to
                ensure that your IT system evolves alongside your business,
                maintaining peak performance and reliability.
              </TimelineItem>
            </Timeline>
          </div>
        </div>
      </section>
    );
  }
}

Process.propTypes = propTypes;
Process.defaultProps = defaultProps;

export default Process;
