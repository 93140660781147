import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Logo from "./partials/Logo";

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  active: false,
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

const Header = (props) => {
  const {
    className,
    active,
    navPosition,
    hideNav,
    hideSignin,
    bottomOuterDivider,
    bottomDivider,
    ...restProps
  } = props;

  const [isActive, setIsActive] = useState(false);
  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    if (active) {
      openMenu();
    }
    const keyPressHandler = (e) => {
      if (isActive && e.keyCode === 27) {
        closeMenu();
      }
    };
    const clickOutsideHandler = (e) => {
      if (!nav.current) return;
      if (
        !isActive ||
        nav.current.contains(e.target) ||
        e.target === hamburger.current
      ) {
        return;
      }
      closeMenu();
    };

    document.addEventListener("keydown", keyPressHandler);
    document.addEventListener("click", clickOutsideHandler);

    return () => {
      document.removeEventListener("keydown", keyPressHandler);
      document.removeEventListener("click", clickOutsideHandler);
      closeMenu();
    };
  }, [isActive, active]);

  const openMenu = () => {
    document.body.classList.add("off-nav-is-active");
    // if (nav.current) {
    //   nav.current.style.maxHeight = nav.current.scrollHeight + "px";
    // }
    setIsActive(true);
  };

  const closeMenu = () => {
    document.body.classList.remove("off-nav-is-active");
    // if (nav.current) {
    //   nav.current.style.maxHeight = null;
    // }
    setIsActive(false);
  };

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  );

  return (
    <header {...restProps} className={classes}>
      <div className="container">
        <div
          className={classNames(
            "site-header-inner",
            bottomDivider && "has-bottom-divider"
          )}
        >
          <Logo />
          {!hideNav && (
            <React.Fragment>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={classNames(
                  "header-nav",
                  isActive && "is-active"
                )}
                style={{ maxHeight: isActive ? "none" : "0px" }}
              >
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-${navPosition}`
                    )}
                  >
                    <li>
                      <Link to="#process" onClick={closeMenu}>
                        About
                      </Link>
                    </li>
                  </ul>
                  {!hideSignin && (
                    <ul className="list-reset header-nav-right">
                      <li>
                        <Link
                          to="/signup/"
                          className="button button-primary button-wide-mobile button-sm"
                          onClick={closeMenu}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </nav>
            </React.Fragment>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;








// import React from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";
// import { Link } from "react-router-dom";
// import Logo from "./partials/Logo";

// const propTypes = {
//   active: PropTypes.bool,
//   navPosition: PropTypes.string,
//   hideNav: PropTypes.bool,
//   hideSignin: PropTypes.bool,
//   bottomOuterDivider: PropTypes.bool,
//   bottomDivider: PropTypes.bool,
// };

// const defaultProps = {
//   active: false,
//   navPosition: "",
//   hideNav: false,
//   hideSignin: false,
//   bottomOuterDivider: false,
//   bottomDivider: false,
// };

// class Header extends React.Component {
//   state = {
//     isActive: false,
//   };

//   nav = React.createRef();
//   hamburger = React.createRef();

//   componentDidMount() {
//     this.props.active && this.openMenu();
//     document.addEventListener("keydown", this.keyPress);
//     document.addEventListener("click", this.clickOutside);
//   }

//   componentWillUnmount() {
//     document.removeEventListener("keydown", this.keyPress);
//     document.addEventListener("click", this.clickOutside);
//     this.closeMenu();
//   }

//   openMenu = () => {
//     document.body.classList.add("off-nav-is-active");
//     this.nav.current.style.maxHeight = this.nav.current.scrollHeight + "px";
//     this.setState({ isActive: true });
//   };


  

//   closeMenu = () => {
//     document.body.classList.remove("off-nav-is-active");
//     this.nav.current && (this.nav.current.style.maxHeight = null);
//     this.setState({ isActive: false });
//   };

//   keyPress = (e) => {
//     this.state.isActive && e.keyCode === 27 && this.closeMenu();
//   };

//   clickOutside = (e) => {
//     if (!this.nav.current) return;
//     if (
//       !this.state.isActive ||
//       this.nav.current.contains(e.target) ||
//       e.target === this.hamburger.current
//     )
//       return;
//     this.closeMenu();
//   };

//   render() {
//     const {
//       className,
//       active,
//       navPosition,
//       hideNav,
//       hideSignin,
//       bottomOuterDivider,
//       bottomDivider,
//       ...props
//     } = this.props;

//     const classes = classNames(
//       "site-header",
//       bottomOuterDivider && "has-bottom-divider",
//       className
//     );
//     console.log(this.nav.current.scrollHeight , 'data');

//     return (
//       <header {...props} className={classes}>
//         <div className="container">
//           <div
//             className={classNames(
//               "site-header-inner",
//               bottomDivider && "has-bottom-divider"
//             )}
//           >
//             <Logo />
//             {!hideNav && (
//               <React.Fragment>
//                 <button
//                   ref={this.hamburger}
//                   className="header-nav-toggle"
//                   onClick={this.state.isActive ? this.closeMenu : this.openMenu}
//                 >
//                   <span className="screen-reader">Menu</span>
//                   <span className="hamburger">
//                     <span className="hamburger-inner"></span>
//                   </span>
//                 </button>
//                 <nav
//                   ref={this.nav}
//                   className={classNames(
//                     "header-nav",
//                     this.state.isActive && "is-active"
//                   )}
//                   style={{ maxHeight: this.state.isActive ? "none" : "0px" }}
//                 >
//                   <div className="header-nav-inner">
//                     <ul
//                       className={classNames(
//                         "list-reset text-xs",
//                         navPosition && `header-nav-${navPosition}`
//                       )}
//                     >
//                       <li>
//                         <Link to="/secondary/" onClick={this.closeMenu}>
//                           About
//                         </Link>
//                       </li>
//                     </ul>
//                     {!hideSignin && (
//                       <ul className="list-reset header-nav-right">
//                         <li>
//                           <Link
//                             to="/signup/"
//                             className="button button-primary button-wide-mobile button-sm"
//                             onClick={this.closeMenu}
//                           >
//                             Contact Us
//                           </Link>
//                         </li>
//                       </ul>
//                     )}
//                   </div>
//                 </nav>
//               </React.Fragment>
//             )}
//           </div>
//         </div>
//       </header>
//     );
//   }
// }

// Header.propTypes = propTypes;
// Header.defaultProps = defaultProps;

// export default Header;
