import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import Image from "../../elements/Image";
import SectionHeader from "../../sections/partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class MainServices extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tiles section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "",
      paragraph: "",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("../../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Web & App Development</h4>
                    <p className="m-0 text-sm">
                      We design and develop stunning, user-friendly, and highly
                      functional websites and applications tailored to address
                      real-world customer needs.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="100"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("../../../assets/images/feature-tile-icon-02.svg")}
                        alt="Features tile icon 02"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Software Development</h4>
                    <p className="m-0 text-sm">
                      We craft innovative, reliable, and scalable software
                      solutions that effectively address real-world challenges
                      and deliver value to our clients.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("../../../assets/images/feature-tile-icon-03.svg")}
                        alt="Features tile icon 03"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Cloud & Infrastructure</h4>
                    <p className="m-0 text-sm">
                      Our Cloud Cost Optimization services are tailored to meet
                      your financial objectives, focusing on every aspect from
                      resource management and efficiency to strategic planning
                      and scalability, ensuring you get the most value from your
                      cloud investments.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="300"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("../../../assets/images/feature-tile-icon-04.svg")}
                        alt="Features tile icon 04"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Emerging Technology</h4>
                    <p className="m-0 text-sm">
                      We provide end-to-end cloud and security solutions to
                      safeguard and enhance your business operations. Our expert
                      service providers excel in cloud consulting,
                      cybersecurity, and smooth cloud migrations, ensuring your
                      data remains secure, your systems operate efficiently, and
                      your business stays prepared for the future.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("../../../assets/images/feature-tile-icon-05.svg")}
                        alt="Features tile icon 05"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Staff Augmentation</h4>
                    <p className="m-0 text-sm">
                      Skip the hassle of multiple interview rounds. Select from
                      a curated pool of experienced developers, assessed for
                      their technical expertise, seniority, and communication
                      skills.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="500"
              >
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require("../../../assets/images/feature-tile-icon-06.svg")}
                        alt="Features tile icon 06"
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">Marketing</h4>
                    <p className="m-0 text-sm">
                      Enhance Your Online Presence with Expert Digital Marketing
                      Solutions Unlock success by leveraging data-driven
                      strategies and compelling digital campaigns designed to
                      make a lasting impact and drive measurable results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

MainServices.propTypes = propTypes;
MainServices.defaultProps = defaultProps;

export default MainServices;
