import React, { useState } from "react";
import { Link } from "react-router-dom";
import { services_data } from "../../utils/data/services.data";


const ServicesDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleDropdown = () => {
    if(isDropdownOpen === true){
        setIsDropdownOpen(!isDropdownOpen);
        setActiveCategory(null)
    }else {
        setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const toggleCategory = (category) => {
    setActiveCategory(activeCategory === category ? null : category);
  };

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
      }}
    >
      {/* Services Button */}
      <button
        onClick={toggleDropdown}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          fontSize: "16px",
          color: "#95A4B4",
        }}
      >
        Services
      </button>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div
         className="dropdown_box"
        >
          {services_data.map((category, index) => (
            <div key={index}>
              {/* Category Header */}
              <div
                style={{
                  padding: "0.75rem 1rem",
                //   color:"#2174EA",
                  cursor: "pointer",
                  fontWeight: "bold",
                  background: activeCategory === category.title ? "#1c2a3d" : "#1b3453",
                  borderBottom: "1px solid #41546c",
                }}
                onClick={() => toggleCategory(category.title)}
              >
                {category.title}
              </div>

              {/* Subcategories */}
              {activeCategory === category.title && (
                <div
                  style={{
                    listStyle: "none",
                    margin: 0,
                    padding: "0.5rem 1rem",
                  }}
                >
                  {category.children.map((service, idx) => (
                    <li key={idx} style={{ marginBottom: "0.5rem" }}>
                      <Link
                        to={"/services"+ service.url}
                        style={{
                          textDecoration: "none",
                        //   color: "#374151",
                        color:"white",
                          fontSize: "14px",
                          padding: '0'
                        }}
                        onClick={()=>{toggleDropdown(); toggleCategory(category.title)}}
                      >
                        {service.title}
                      </Link>
                    </li>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ServicesDropdown;
