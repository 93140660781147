import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import Image from "../../elements/Image";
import SectionHeader from "../../sections/partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Hero extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      sectionHeader,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "testimonial-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    // const sectionHeader = {
    //   title: "About Us",
    //   paragraph: `DigiKart is not just a marketplace; we are the architects of digital transformation. With a passion for technology and a pulse on the latest trends, we bring together a diverse ecosystem of developers, businesses, and innovators under one virtual roof.

    //   We envision a world where every business, regardless of size or industry, has access to the tools and expertise needed to navigate the digital realm successfully. Our marketplace is built upon the pillars of innovation, collaboration, and accessibility.
    //   `,
    // };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses} style={{ minHeight: "600px" }}>
            <SectionHeader
              data={sectionHeader}
              tag="h1"
              className="center-content invert-color"
            />
            <div className={tilesClasses}>
              {/* <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("../../../assets/images/testimonial-image-01.jpg")}
                        alt="Testimonial 01"
                        width={48}
                        height={48}
                      />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Duis aute irure dolor in reprehenderit in voluptate
                      velit esse cillum dolore eu fugiat nulla pariatur.
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum
                      cillum dolore eu fugiat culpa qui officia deserunt mollit
                      anim id est laborum.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">Kendra Kinson</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link fw-600">
                      <a href="#0">AppName</a>
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("../../../assets/images/testimonial-image-02.jpg")}
                        alt="Testimonial 02"
                        width={48}
                        height={48}
                      />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Duis aute irure dolor in reprehenderit in voluptate
                      velit esse cillum dolore eu fugiat nulla pariatur.
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum
                      cillum dolore eu fugiat culpa qui officia deserunt mollit
                      anim id est laborum.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">Emilia Jovic</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link fw-600">
                      <a href="#0">AppName</a>
                    </span>
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                    <div className="testimonial-item-image">
                      <Image
                        src={require("../../../assets/images/testimonial-image-03.jpg")}
                        alt="Testimonial 03"
                        width={48}
                        height={48}
                      />
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                      — Duis aute irure dolor in reprehenderit in voluptate
                      velit esse cillum dolore eu fugiat nulla pariatur.
                      Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum
                      cillum dolore eu fugiat culpa qui officia deserunt mollit
                      anim id est laborum.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <span className="testimonial-item-name">Mark Walker</span>
                    <span className="text-color-low"> / </span>
                    <span className="testimonial-item-link fw-600">
                      <a href="#0">AppName</a>
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
