import React, { Component } from "react";

class Location extends Component {
  render() {
    const mapStyles = {
      width: "80%",
      display: "block",
      margin: "auto",
      height: "400px",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      border: "none",
    };

    return (
      <div style={{ margin: "20px 0" }}>
        <iframe
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3543.259134228633!2d77.06826416779757!3d28.491288859807806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d192fc20b97e7%3A0x3048b25634f6d955!2sNagarro!5e0!3m2!1sen!2sin!4v1731876326400!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={mapStyles}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    );
  }
}

export default Location;
