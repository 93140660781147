import React, { Component } from "react";
import Input from "../../elements/Input";
import Button from "../../elements/Button";
import { services_data } from "../../../utils/data/services.data";
import swal from 'sweetalert';


class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        // first_name: "",
        // last_name: "",
        full_name: "",
        email: "",
        service: "",
        mobile_number: "",
      },
      errors: {
        // first_name: "",
        // last_name: "",
        full_name: "",
        email: "",
        service: "",
        mobile_number: "",
      },
      isSubmitted: false,
    };
  }

  // Handle change in form input fields
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  // Validate the form data
  validateForm = () => {
    let formErrors = {};
    let isValid = true;
    const { full_name, email, service, mobile_number } =
      this.state.formData;

    // // First name validation
    // if (!first_name.trim()) {
    //   formErrors.first_name = "First Name is required.";
    //   isValid = false;
    // }

    // // Last name validation
    // if (!last_name.trim()) {
    //   formErrors.last_name = "Last Name is required.";
    //   isValid = false;
    // }

    // Full name validation
    if (!full_name.trim()) {
      formErrors.full_name = "Name is required.";
      isValid = false;
    }

    // Email validation
    if (!email.trim()) {
      formErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email is not valid.";
      isValid = false;
    }

    // Service validation
    if (!service) {
      formErrors.service = "Please select a service.";
      isValid = false;
    }

    // Mobile number validation
    if (!mobile_number.trim()) {
      formErrors.mobile_number = "Mobile number is required.";
      isValid = false;
    } else if (!/^\d{10}$/.test(mobile_number)) {
      formErrors.mobile_number = "Mobile number must be 10 digits.";
      isValid = false;
    }

    this.setState({ errors: formErrors });
    return isValid;
  };

  // Handle form submission
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      console.log("Form Submitted", this.state.formData);
      // this.setState({ isSubmitted: true });    // to show form submit status below the button

      // adding toast on sucessfull form submition
      setTimeout(() => {
        swal("Thanks!", "One of our executive will connect with you shortly.", "success");
      }, 1500);

    } else {
      // this.setState({ isSubmitted: false });
    }
  };

  render() {
    const { formData, errors, isSubmitted } = this.state;

    return (
      <div
        // className="container-xs"
        style={{
          width: "40%",
          background: "white",
          padding: "30px",
          borderRadius: "15px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
        }}
      >
        
        {/* <h2 className="mt-0">Contact Us for Our Services</h2>
        <p>
          Please fill in the form below to get in touch with us for our
          services.
        </p> */}
        <form
          onSubmit={this.handleSubmit}
          style={{ maxWidth: "420px", margin: "0 auto" }}
        >
          {/* <div className="mb-24">
            <Input
              type="text"
              label="First Name"
              placeholder="Your first name"
              name="first_name"
              value={formData.first_name}
              onChange={this.handleChange}
              status={errors.first_name ? "error" : ""}
              hint={errors.first_name}
            />
          </div> */}
          <div className="mb-24">
            <Input
              type="text"
              label="Full Name"
              placeholder="Your name"
              name="full_name"
              value={formData.full_name}
              onChange={this.handleChange}
              status={errors.full_name ? "error" : ""}
              hint={errors.full_name}
            />
          </div>
          <div className="mb-24">
            <Input
              type="email"
              label="Email"
              placeholder="Your email address"
              name="email"
              value={formData.email}
              onChange={this.handleChange}
              status={errors.email ? "error" : ""}
              hint={errors.email}
            />
          </div>
          <div className="mb-24">
            <label htmlFor="service" className="form-label">
              Service
            </label>
            <select
              id="service"
              name="service"
              value={formData.service}
              onChange={this.handleChange}
              className={`form-control ${
                errors.service ? "is-invalid" : "is-valid"
              }`}
              style={{
                display: "block",
                padding: "8px 16px",
                width: "100%",
                border: "1px solid #efeded",
                borderRadius: "4px",
                height: "48px",
                fontSize: "16px",
                outline: "none",
              }}
            >
              <option style={{ color: "lightgray" }} value="">
                Select a service
              </option>
              {/* <option value="web_design">Web Design</option>
              <option value="seo_optimization">SEO Optimization</option>
              <option value="app_development">App Development</option>
              <option value="digital_marketing">Digital Marketing</option> */}
              {services_data.map((service)=>(
                service.children.map((subService)=>(
                  <option value={subService.title} key={subService.title}>{subService.title}</option>
                ))
              ))}
            </select>
            {errors.service && (
              <small className="text-danger">{errors.service}</small>
            )}
          </div>
          <div className="mb-24">
            <Input
              type="text"
              label="Mobile Number"
              placeholder="Your mobile number"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={this.handleChange}
              status={errors.mobile_number ? "error" : ""}
              hint={errors.mobile_number}
            />
          </div>
          <Button
            color="primary"
            style={{ display: "block", width: "200px", margin: "auto" }}
          >
            Submit
          </Button>
          {isSubmitted && (
            <div className="mt-24 text-color-success" style={{textAlign: "center"}}>
              Form submitted successfully!
            </div>
          )}
        </form>

      </div>
    );
  }
}

export default ContactForm;
