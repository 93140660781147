import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";

import Image from "../../elements/Image";
import SectionHeader from "../../sections/partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Testimonials extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "news section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "news-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "What Our Clients Say",
      paragraph:
        "Real experiences, real stories—see how we’ve made a difference.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-bottom"
            />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner has-shadow">
                  {/* <figure className="news-item-image m-0">
                    <Image
                      src={require("../../../assets/images/news-image-01.jpg")}
                      alt="News 01"
                      width={344}
                      height={194}
                    />
                  </figure> */}
                  <section
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      padding: "24px",
                      paddingBottom: "0",
                    }}
                  >
                    <div
                      style={{
                        height: "3rem",
                        width: "3rem",
                        borderRadius: "50%",
                        padding: "4px",
                        backgroundColor: "lightgrey",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={
                          "https://d3o5s7owoun4ez.cloudfront.net/astroAssets/images/jockey.webp"
                        }
                        alt="News 01"
                        width={344}
                        height={194}
                      />
                    </div>
                    <div
                      className="news-item-more text-xs mb-8"
                      style={{ textAlign: "right" }}
                    >
                      Jeevant Sarma,
                      <span style={{ fontWeight: 600 }}> Jockey</span>
                    </div>
                  </section>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      {/* <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="#">Excellent Support!</a>
                      </h3> */}
                      <p className="mb-16 text-sm">
                        One of the best teams I've worked with. Amazing designs,
                        amazing work ethic and exceptional energy. If you're
                        looking to revamp your digital presence or build a fresh
                        brand, look no further. Orbit Global is the place to go
                        to.
                      </p>
                    </div>
                    {/* <div
                      className="news-item-more text-xs mb-8"
                      style={{ textAlign: "right" }}
                    >
                      -- Jeevant Sarma,
                      <br />
                      <span style={{ fontWeight: 600 }}>
                      Jockey
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  {/* <figure className="news-item-image m-0">
                    <Image
                      src={require("../../../assets/images/news-image-02.jpg")}
                      alt="News 02"
                      width={344}
                      height={194}
                    />
                  </figure> */}
                  <section
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      padding: "24px",
                      paddingBottom: "0",
                    }}
                  >
                    <div
                      style={{
                        height: "3rem",
                        width: "3rem",
                        borderRadius: "50%",
                        padding: "4px",
                        backgroundColor: "lightgrey",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={
                          "https://d3o5s7owoun4ez.cloudfront.net/astroAssets/icons/gulf-oil.svg"
                        }
                        alt="News 01"
                        width={344}
                        height={194}
                      />
                    </div>
                    <div
                      className="news-item-more text-xs mb-8"
                      style={{ textAlign: "right" }}
                    >
                      Supreet Singh,
                      <span style={{ fontWeight: 600 }}> Gulf Oil</span>
                    </div>
                  </section>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      {/* <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="#">Boosted Our Efficiency</a>
                      </h3> */}
                      <p className="mb-16 text-sm">
                        To the extraordinary Orbit Global team, your skillful
                        commitment and dedication are evident in every pixel.
                        Your unwavering pursuit of excellence promises a bright
                        future. Embrace the journey, learn from setbacks, and
                        build upon strengths to soar to greater heights. Success
                        is within reach, keep shining!
                      </p>
                    </div>
                    {/* <div
                      className="news-item-more text-xs mb-8"
                      style={{ textAlign: "right" }}
                    >
                      -- Supreet Singh,
                      <br />
                      <span style={{ fontWeight: 600 }}>Gulf Oil</span>
                    </div> */}
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="400"
              >
                <div className="tiles-item-inner has-shadow">
                  {/* <figure className="news-item-image m-0">
                    <Image
                      src={require("../../../assets/images/news-image-03.jpg")}
                      alt="News 03"
                      width={344}
                      height={194}
                    />
                  </figure> */}
                  <section
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      padding: "24px",
                      paddingBottom: "0",
                    }}
                  >
                    <div
                      style={{
                        height: "3rem",
                        width: "3rem",
                        borderRadius: "50%",
                        padding: "4px",
                        backgroundColor: "lightgrey",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Image
                        src={require("../../../assets/images/asus.png")}
                        alt="News 01"
                        width={344}
                        height={194}
                      />
                    </div>
                    <div
                      className="news-item-more text-xs mb-8"
                      style={{ textAlign: "right" }}
                    >
                      Gauri Bhagwat,
                      <span style={{ fontWeight: 600 }}> Asus</span>
                    </div>
                  </section>
                  <div className="news-item-content">
                    <div className="news-item-body">
                      {/* <h3 className="news-item-title h4 mt-0 mb-8">
                        <a href="#">Reliable and Trusted Partner</a>
                      </h3> */}
                      <p className="mb-16 text-sm">
                        We collaborated with Orbit Global for revamping our
                        website and I must say the team exhibited a level of
                        professionalism throughout the journey. Special thanks
                        to Pratibha from Orbit Global who actively passed our
                        feedback to the team and ensured the final product
                        aligned with our vision. Look forward to continuing
                        working with them.
                      </p>
                    </div>
                    {/* <div
                      className="news-item-more text-xs mb-8"
                      style={{ textAlign: "right" }}
                    >
                      -- Gauri Bhagwat,
                      <br />
                      <span style={{ fontWeight: 600 }}>Asus</span>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonials.propTypes = propTypes;
Testimonials.defaultProps = defaultProps;

export default Testimonials;
