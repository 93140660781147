import React from "react";
import Services from "../components/templates/Services";
import Testimonials from "../components/templates/Testimonials";
import Process from "../components/templates/Process";
import Newsletter from "../components/templates/Newsletter";
import MainServices from "../components/templates/Main Services";
import LandingHero from "../components/templates/LandingHero";
import Clientails from "../components/templates/Clientails";
import Faqs from "../components/templates/Faqs";
import ServicesHero from "../components/templates/Hero/ServiceHero";
import { useParams } from "react-router-dom";
import { services_data } from "../utils/data/services.data";

function SubService() {
  const { id } = useParams();

  const matched_data = services_data
    .map((ele) => ele.children.find((item) => item.url === "/" + id))
    .find((item) => item !== undefined);

  return (
    <React.Fragment>
      <ServicesHero hasBgColor invertColor matched_data={matched_data} />
      <Clientails topDivider bottomDivider />
      {/* <MainServices /> */}
      {/* <Services topDivider bottomOuterDivider /> */}
      <Testimonials className="illustration-section-01" />
      <Process topDivider />
      <Faqs />
      <Newsletter invertColor split className="has-bg-color-cut" />
    </React.Fragment>
  );
}

export default SubService;
