import React from 'react';
import Image from '../components/elements/Image';

const ComingSoon = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        backgroundImage: "url('https://vojislavd.com/ta-template-demo/assets/img/coming-soon.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          padding: '2rem 0',
        }}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
              padding: '0.5rem 1rem',
              borderRadius: '0.75rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#cffafe',
              gap: '1rem',
            }}
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{ height: '2.5rem', width: '2.5rem' }}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
              />
            </svg> */}
            <h1 className="m-0">
                <a href="/">
                  <Image
                    src={"/OG_Logo.png"}
                    alt="Tidy"
                    width={160}
                    height={160}
                  />
                </a>
              </h1>
            {/* <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Orbit Global</span> */}
          </div>
          <h1
            style={{
              fontSize: '4rem',
              color: '#e5e7eb',
              fontWeight: 'bold',
              fontFamily: 'serif',
              marginTop: '3rem',
              textAlign: 'center',
            }}
          >
            Coming Soon
          </h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '1rem',
              marginTop: '6rem',
            }}
          >
            <p
              style={{
                color: '#d1d5db',
                textTransform: 'uppercase',
                fontSize: '0.875rem',
              }}
            >
              Notify me when it's ready
            </p>
            <form
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                autoComplete="off"
                style={{
                  width: '18rem',
                  padding: '0.5rem',
                  border: '1px solid #d1d5db',
                  borderTopLeftRadius: '0.375rem',
                  borderBottomLeftRadius: '0.375rem',
                  outline: 'none',
                  fontSize: '0.875rem',
                }}
              />
              <button
                style={{
                  backgroundColor: '#2563eb',
                  color: '#f9fafb',
                  padding: '0.5rem 1.5rem',
                  border: '1px solid #2563eb',
                  borderTopRightRadius: '0.375rem',
                  borderBottomRightRadius: '0.375rem',
                  fontSize: '0.875rem',
                  cursor: 'pointer',
                }}
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <ul style={{ display: 'flex', gap: '1rem' }}>
            <li>
              <a href="#" title="Facebook">
                <svg
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    transition: 'transform 0.3s',
                  }}
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 506.86 506.86"
                >
                  <path d="M506.86,253.43C506.86,113.46,393.39,0,253.43,0S0,113.46,0,253.43C0,379.92,92.68,484.77,213.83,503.78V326.69H149.48V253.43h64.35V197.6c0-63.52,37.84-98.6,95.72-98.6,27.73,0,56.73,5,56.73,5v62.36H334.33c-31.49,0-41.3,19.54-41.3,39.58v47.54h70.28l-11.23,73.26H293V503.78C414.18,484.77,506.86,379.92,506.86,253.43Z"></path>
                </svg>
              </a>
            </li>

          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default ComingSoon;
