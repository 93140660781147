import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import Image from "../../elements/Image";
import SectionHeader from "../../sections/partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Team extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "team section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "team-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "The Minds Behind Our Success",
      paragraph:
        "With a shared vision and diverse skill sets, our team works together to create transformative experiences for our clients",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-bottom"
            />
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={
                          "https://media.licdn.com/dms/image/v2/D5603AQF4Eue3TGoTcQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1722273968279?e=1737590400&v=beta&t=JM12eO9c6mvqv6aX39Q_Hn3C5lZWYagdeLxpbQmwa0U"
                        }
                        alt="Team member 01"
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">Keshav Singh</h5>
                    <div className="team-item-role text-xs fw-600 mb-8">
                      Founder
                    </div>
                    {/* <p className="m-0 text-sm">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod.
                    </p> */}
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="150"
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={
                          "https://media.licdn.com/dms/image/v2/D5603AQGtl8NMmzU7sA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1731352601496?e=1737590400&v=beta&t=bATQfNGB7QQ_escJdShIeXA7iuoPhtmVs_iwxZd3v8E"
                        }
                        alt="Team member 02"
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">Rishi Raj</h5>
                    <div className="team-item-role text-xs fw-600 mb-8">
                      Founder
                    </div>
                    {/* <p className="m-0 text-sm">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod.
                    </p> */}
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="300"
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={
                          "https://media.licdn.com/dms/image/v2/D4D03AQHpyY7xfF67Vg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1685940799047?e=1737590400&v=beta&t=BSflq5mvXQlR4-CIYBjIOr2muU3Sip3zbOnfy8c52PE"
                        }
                        alt="Team member 03"
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">Hari Karki</h5>
                    <div className="team-item-role text-xs fw-600 mb-8">
                      Sales Head
                    </div>
                    {/* <p className="m-0 text-sm">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod.
                    </p> */}
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="450"
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={
                          "https://media.licdn.com/dms/image/v2/D5603AQHPRpPGKvSoeQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1730723665521?e=1737590400&v=beta&t=f5v5MEl3LeNEpD7XKasnAors8LsdSiJJfucpTW1XYS4"
                        }
                        alt="Team member 04"
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      LAXMI NARAIN SHARMA
                    </h5>
                    <div className="team-item-role text-xs fw-600 mb-8">
                      Bussiness Operations Head
                    </div>
                    {/* <p className="m-0 text-sm">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod.
                    </p> */}
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="600"
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={
                          "https://media.licdn.com/dms/image/v2/C4E03AQEcXTjG2tSMXQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1517722188963?e=1737590400&v=beta&t=xl7FXq6-JNw0zrpfhzFSmL44fYV9_OE5tBbXu3FcTBo"
                        }
                        alt="Team member 05"
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                      Gunjan Aggarwal
                    </h5>
                    <div className="team-item-role text-xs fw-600 mb-8">
                      Operation Head
                    </div>
                    {/* <p className="m-0 text-sm">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod.
                    </p> */}
                  </div>
                </div>
              </div>

              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-container=".tiles-wrap"
                data-reveal-delay="750"
              >
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={
                          "https://media.licdn.com/dms/image/v2/C5603AQHOaC7dNOBvEA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1516979477111?e=1737590400&v=beta&t=PJ_iTEM690zaeJV2XDm-S53zXFbLtJ8ucNDqoWyeWxA"
                        }
                        alt="Team member 06"
                        width={180}
                        height={180}
                      />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">Arpit Anand</h5>
                    <div className="team-item-role text-xs fw-600 mb-8">
                      Marketing Head
                    </div>
                    {/* <p className="m-0 text-sm">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
