export const services_data = [
  {
    title: "Development",
    children: [
      {
        title: "Ecommerce Solutions",
        description:
          "We design and develop custom e-commerce solutions tailored to your business's unique needs, from user-friendly shopping experiences to secure payment integrations. Our expertise in web and mobile development ensures optimal performance and user satisfaction.",
        img: "/icons/AppWebsite.png",
        url: "/ecommerce-solutions",
        // icons: [
        //   {
        //     title: "React",
        //     icon_url: "/icons/react.png",
        //   },
        //   {
        //     title: "Angular",
        //     icon_url: "/icons/angular.png",
        //   },
        //   {
        //     title: "Flutter",
        //     icon_url: "/icons/flutter.png",
        //   },
        // ],
      },
      {
        title: "Custom ERP",
        description:
          "We develop custom ERP systems that optimize business operations, integrating essential functions like finance, inventory, and customer management into one cohesive system. Our tailored solutions help streamline processes and enhance productivity.",
        img: "/icons/E-Commerse.png",
        url: "/custom-erp",
      },
      {
        title: "Fintech Solutions",
        description:
          "We provide specialized fintech development services to help businesses create secure and scalable financial platforms. Our solutions include banking apps, investment platforms, and financial management tools designed with the latest technology to ensure efficiency and security.",
        img: "/icons/Epr.png",
        url: "/epr",
      },
      {
        title: "Edutech Solutions",
        description:
          "We create engaging and interactive edtech applications designed to enhance learning experiences. Our solutions cater to various educational needs, from e-learning platforms to digital classrooms, integrating tools that foster collaboration and knowledge sharing.",
        img: "/icons/GameDevelopment.png",
        url: "/edutech-solutions",
      },
      {
        title: "On Demand Applications",
        description:
          "We develop on-demand applications that allow users to access services and products quickly and efficiently. From ride-sharing apps to food delivery services, we ensure a seamless user experience through intuitive design and robust functionality.",
        img: "/icons/GameDevelopment.png",
        url: "/on-demand-applications",
      },
    ],
  },
  {
    title: "Marketing",
    children: [
      {
        title: "Performance Marketing",
        description:
          "Our performance marketing strategies focus on delivering measurable results. We design data-driven campaigns that optimize ROI through targeted ads, retargeting, and advanced conversion techniques across digital platforms.",
        img: "/icons/SocialMedia.png",
        url: "/performance-marketing",
      },
      {
        title: "Social Media Marketing",
        description:
          "We help businesses strengthen their brand presence across social media platforms like Facebook, Instagram, and LinkedIn. Our services include strategic content creation, community management, and engagement tactics that foster a loyal audience base and drive sales.",
        img: "/icons/Seo.png",
        url: "/social-media-marketing",
      },
      {
        title: "SEO Services",
        description:
          "Our comprehensive SEO services aim to improve your website's visibility and organic traffic. We utilize in-depth keyword research, on-page optimization, technical SEO, and link-building strategies to ensure top search engine rankings.",
        img: "/icons/PerformanceMarketing.png",
        url: "/seo",
      },
      {
        title: "Media Strategy & Planning",
        description:
          "Our media strategy and planning services help you effectively allocate your marketing budget to maximize reach and engagement. We create data-informed media plans that connect your brand with the right audience at the right time.",
        img: "/icons/Branding.png",
        url: "/media-strategy-planning",
      },
      {
        title: "ATL & BTL Marketing Strategies",
        description:
          "We offer both Above-The-Line (ATL) and Below-The-Line (BTL) marketing strategies to reach your audience through traditional and direct channels. From TV and print advertising to targeted promotional campaigns, we ensure your brand message is impactful and memorable.",
        img: "/icons/Branding.png",
        url: "/atl-btl-marketing-strategies",
      },
    ],
  },
  {
    title: "Emerging Technologies",
    children: [
      {
        title: "AI/ML",
        description:
          "We leverage AI and machine learning to develop innovative solutions that enhance automation, data analysis, and predictive modeling. From chatbots to intelligent analytics platforms, we build smart systems that drive business insights and efficiencies.",
        img: "/icons/ScrumMaster.png",
        url: "/ai-ml",
      },
      {
        title: "Web 3.0",
        description:
          "Our expertise in Web 3.0 technologies empowers businesses to create decentralized applications and blockchain-based platforms. We guide you through the development and integration of secure, user-centric solutions that align with the future of the internet.",
        img: "/icons/ProjectManager.png",
        url: "/web-3",
      },
      {
        title: "Industry 4.0 Solutions & IoT",
        description:
          "We design and implement Industry 4.0 and IoT solutions that connect machines, equipment, and systems to optimize production and operational efficiency. Our services include smart factory setups, real-time data analysis, and predictive maintenance technologies.",
        img: "/icons/ProductManager.png",
        url: "/industry-solutions-iot",
      },
      {
        title: "AR/VR & Metaverse",
        description:
          "We develop cutting-edge AR/VR and metaverse experiences that offer immersive, interactive environments. Our solutions enhance user engagement through 3D simulations, virtual tours, and interactive training modules.",
        img: "/icons/ProductManager.png",
        url: "/ar-vr-metaverse",
      },
      {
        title: "Cloud & Managed Services",
        description:
          "Our cloud and managed services help businesses migrate, manage, and optimize their cloud infrastructure. We offer scalable, secure solutions that ensure high availability, data protection, and cost savings across multiple cloud platforms.",
        img: "/icons/ProductManager.png",
        url: "/cloud-managed-services",
      },
    ],
  },
];
