import React from "react";
// import HeroSplit from "../components/sections/HeroSplit";
import FeaturesTiles from "../components/sections/FeaturesTiles";
// import FeaturesTabs from "../components/sections/FeaturesTabs";
import News from "../components/sections/News";
import Roadmap from "../components/sections/Roadmap";
import Pricing from "../components/sections/Pricing";
import Cta from "../components/sections/Cta";
import Services from "../components/templates/Services";
import Testimonials from "../components/templates/Testimonials";
import Process from "../components/templates/Process";
import Newsletter from "../components/templates/Newsletter";
import Clientails from "../components/templates/Clientails";
import Usp from "../components/templates/USP";
import Faqs from "../components/templates/Faqs";
import Team from "../components/templates/Team";
import Hero from "../components/templates/Hero";

class About extends React.Component {
  render() {
    const sectionHeader = {
      title: "Mission and Vission",
      paragraph: `DigiKart is not just a marketplace; we are the architects of digital transformation. With a passion for technology and a pulse on the latest trends, we bring together a diverse ecosystem of developers, businesses, and innovators under one virtual roof.
  
        We envision a world where every business, regardless of size or industry, has access to the tools and expertise needed to navigate the digital realm successfully. Our marketplace is built upon the pillars of innovation, collaboration, and accessibility.
        `,
    };
    return (
      <React.Fragment>
        <Hero
          className="has-bg-color-cut illustration-section-02"
          sectionHeader={sectionHeader}
        />
        {/* <Team /> */}
        <Clientails topDivider bottomDivider />
        <Usp invertMobile imageFill topDivider />
        <Faqs />
        <Newsletter invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default About;
