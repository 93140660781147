import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../../utils/SectionProps";
import Image from "../../elements/Image";
import SectionHeader from "../../sections/partials/SectionHeader";
import ContactDetails from "../ContactDetails";
import ContactForm from "../ContactForm";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class ServicesHero extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      sectionHeader,
      matched_data,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "testimonial-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    // const sectionHeader = {
    //   title: "About Us",
    //   paragraph: `DigiKart is not just a marketplace; we are the architects of digital transformation. With a passion for technology and a pulse on the latest trends, we bring together a diverse ecosystem of developers, businesses, and innovators under one virtual roof.

    //   We envision a world where every business, regardless of size or industry, has access to the tools and expertise needed to navigate the digital realm successfully. Our marketplace is built upon the pillars of innovation, collaboration, and accessibility.
    //   `,
    // };

    return (
      <section {...props} className={outerClasses}>
        <div className="" style={{ width: "80%", margin: "auto" }}>
          <div className={innerClasses} style={{ minHeight: "600px" }}>
            {/* <SectionHeader
              data={sectionHeader}
              tag="h1"
              className="center-content invert-color"
            /> */}
            <div className={tilesClasses}>
              <div
                className="contact-section"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  //   alignItems: "center",
                  gap: "2rem",
                  width: "100%",
                  margin: "100px 0",
                }}
              >
                <div
                  className="hero-content split-item-content center-content-mobile reveal-from-top"
                  style={{
                    width: "50%",
                  }}
                >
                  <h1 className="mt-0 mb-16">{matched_data?.title}</h1>
                  <p className="mt-0 mb-32">{matched_data?.description}</p>

                  <div
                    style={{
                      display: "flex",
                      gap: "3rem",
                      margin: "5rem 0",
                    }}
                  >
                    {matched_data?.icons?.map((ele, ind) => (
                      <div key={ind}>
                        <img
                          src={ele.icon_url}
                          alt=""
                          style={{
                            height: "60px",
                          }}
                        />
                        <p>{ele.title}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ServicesHero.propTypes = propTypes;
ServicesHero.defaultProps = defaultProps;

export default ServicesHero;
