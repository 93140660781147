import React from "react";
import Newsletter from "../components/templates/Newsletter";
import Faqs from "../components/templates/Faqs";
import Hero from "../components/templates/Hero";
import ContactForm from "../components/templates/ContactForm";
import ContactDetails from "../components/templates/ContactDetails";
import ContactHero from "../components/templates/Hero/ContactHero";
import Location from "../components/templates/Location";

class Contact extends React.Component {
  render() {
    const sectionHeader = {
      title: "Contact Us",
      paragraph: "",
    };
    return (
      <React.Fragment>
        <ContactHero
          className="has-bg-color-cut illustration-section-02"
          sectionHeader={sectionHeader}
        />
        <Location />
        <Faqs />
        <Newsletter invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Contact;
