import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import Logo from "./partials/LogoInvert";

import { Link } from "react-router-dom";
import Image from "../../elements/Image";
import FooterNav from "../../layout/partials/FooterNav";
import FooterSocial from "../../layout/partials/FooterSocial";
import { services_data } from "../../../utils/data/services.data";

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

class Footer extends React.Component {
  render() {
    const { className, topOuterDivider, topDivider, ...props } = this.props;

    const classes = classNames(
      "site-footer invert-color",
      topOuterDivider && "has-top-divider",
      className
    );

    return (
      <footer {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              "site-footer-inner",
              topDivider && "has-top-divider"
            )}
          >
            <div className="footer-top text-xxs">
              <div className="footer-blocks">
                <div className="footer-block">
                  <div {...props} className={classes}>
                    <h1 className="m-0">
                      <Link to="/">
                        <Image
                          src={"/OG_Logo.png"}
                          alt="Tidy"
                          width={120}
                          height={80}
                        />
                      </Link>
                    </h1>
                  </div>

                  <div className="footer-bottom space-between center-content-mobile text-xxs">
                    {/* <FooterNav /> */}
                    <FooterSocial />
                  </div>
                </div>
                {/* <div className="footer-block">
                  <div className="footer-block-title">Development</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="/services/apps-and-websites">Apps & Websites</a>
                    </li>
                    <li>
                      <a href="">E-Commerse</a>
                    </li>
                    <li>
                      <a href="">EPR</a>
                    </li>
                    <li>
                      <a href="">Game Development</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Marketing</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="">Social Media</a>
                    </li>
                    <li>
                      <a href="">SEO</a>
                    </li>
                    <li>
                      <a href="">Performance Marketing</a>
                    </li>
                    <li>
                      <a href="">Branding</a>
                    </li>
                  </ul>
                </div>
                <div className="footer-block">
                  <div className="footer-block-title">Product Management</div>
                  <ul className="list-reset mb-0">
                    <li>
                      <a href="">Scrum Master</a>
                    </li>
                    <li>
                      <a href="">Project Manager</a>
                    </li>
                    <li>
                      <a href="">Product Manager</a>
                    </li>
                  </ul>
                </div> */}
                {/* Loop through services_data and dynamically generate sections */}
                {services_data.map((category, index) => (
                  <div className="footer-block" key={index}>
                    <div className="footer-block-title">{category.title}</div>
                    <ul className="list-reset mb-0">
                      {category.children.map((service, idx) => (
                        <li key={idx}>
                          <a href={"/services" + service.url}>
                            {service.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="footer-copyright"
              style={{
                marginTop: "1rem",
                textAlign: "center",
              }}
            >
              &copy; 2024 Orbit Global, all rights reserved
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
